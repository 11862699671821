import platformData  from "../config/reducpeyi.json";

const Context = {
  platformData,
  theme: {
    background: "#000",
    foreground: "#fff",
  }
};

export default Context;
