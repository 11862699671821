import React from 'react';
import setupPageHead from "./tools/setupPageHead";
import {Route, Routes} from 'react-router-dom';
import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import Context from './tools/context';
import Script from 'react-load-script';
import './App.scss';
import {withTranslation} from 'react-i18next';

function App() {
  if (!Context.platformData) return null;

  setupPageHead(Context.platformData);

  const routes = [
    {
      exact: true,
      path: '/',
      element: <Landing />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ];

  return (
    <div className="App">
      { Context.platformData?.features?.zendesk && <Script attributes={{id: 'ze-snippet', async: true}} url={`https://static.zdassets.com/ekr/snippet.js?key=${Context.platformData?.features?.zendeskId}`} />  }
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </div>
  );
}

export default withTranslation()(App);
