import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SearchBar from '../../../components/SearchBar';
import CityCard from '../../../components/CityCard';
import ScrollToTop from "../../../components/ScrollToTop";
import {Button} from "../../../components/Buttons";
import Context from '../../../tools/context';
import './index.scss';

function Content() {
  const platformData = Context.platformData;
  const {t} = useTranslation();
  const [filter, setFilter] = useState("");
  const [filteredCities, setFilteredCities] = useState(platformData.cities);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const handleChangeFilter = (value) => {
    setFilter(value);
    if (platformData.cities && platformData.cities.length > 0) {
      const filteredCities = platformData.cities.filter(city => city.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredCities(filteredCities);
    }
  }

  const handleScroll = () => {
    if (window.pageYOffset > window.innerHeight + 100) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  }

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="Content">
      <div className="contentWrapper">
        {platformData.features?.searchBar &&
          <SearchBar
            filter={filter}
            setFilter={handleChangeFilter}
            placeholder={t('searchCity')}
            style={platformData.style}
          />
        }
        <div className="citiesWrapper">
          {filteredCities.length > 0 ?
            filteredCities.map((city, index) => (
              <CityCard
                name={city.name}
                imageSrc={`/assets/${platformData.name}/cities/${city.imageSrc}`}
                url={city.url}
                style={{
                  animationDelay: `calc(${index} * 300ms / ${filteredCities.length % 5}`,
                  ...platformData.style.city
                }}
              />
            )) : <div className="noResults" style={{color: platformData.style.secondaryColor || ''}}>Aucun résultat</div>
          }
        </div>
        {platformData.features?.becomePartner &&
          <div className="becomePartner">
            <Button
              className={"becomePartnerButton"}
              action={() => window.location.href = platformData.features?.becomePartnerUrl || '#'}
              style={{
                color: platformData.style.secondaryColor || '#183E2F',
                borderColor: platformData.style.secondaryColor || '#183E2F',
                fontFamily: platformData.style.primaryFont
            }}
            >
              Devenir ville partenaire
            </Button>
          </div>
        }
        {showScrollToTop && <ScrollToTop backgroundColor={platformData.style.secondaryColor || '#183E2F'} color={'#FFFFFF'} />}
      </div>
    </div>
  )
}

export default Content;