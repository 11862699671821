import React from "react";
import {scrollToSmoothly} from "../../tools";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import './index.scss';

const scrollToTop = ({backgroundColor, color}) => {
    return (
      <div className="ScrollToTop">
        <button
          className={"scrollButton"}
          onClick={() => scrollToSmoothly(window.innerHeight, 1000)}
          style={{background: backgroundColor}}
        ><FontAwesomeIcon icon={faAngleUp} color={color} size={'lg'}/></button>
      </div>
    )
}

export default scrollToTop;