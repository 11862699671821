import React from 'react';
import './index.scss';

const LoadingScreen = ({logoSrc}) => {
  return (
    <div className={"LoadingScreen"}>
      <div className={"loadingScreenContainer"}>
        <img className={"loadingScreenLogo"} src={logoSrc} alt={"loadingScreenLogo"} />
      </div>
    </div>
  )
}

export default LoadingScreen;